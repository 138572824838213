const crypto = require('crypto');
let baseURL = 'http://digimonk.co:1613/api/v1'
baseURL = 'https://megamoveapi.mobikasa.net/api/v1'
const googleMapKey = "AIzaSyC_ubOrxvZx6lla8IEnXaikTIyTn1jj5CM"


export const ValidateEmail = (mail) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true
  }
  return false
}

export const encrypt = ((val) => {

  let cipher = crypto.createCipheriv('aes-256-cbc', Uint8Array.from(atob(localStorage.getItem("encKey")), c => c.charCodeAt(0)), Uint8Array.from(atob(localStorage.getItem("ivKey")), c => c.charCodeAt(0)));
  let encrypted = cipher.update(val, 'utf8', 'base64');
  encrypted += cipher.final('base64');
  return encrypted;
});

export const decrypt = ((encrypted) => {
  return encrypted;
  /*if(encrypted){
  let decipher = crypto.createDecipheriv('aes-256-cbc', Uint8Array.from(atob(localStorage.getItem("encKey")), c => c.charCodeAt(0)),  Uint8Array.from(atob(localStorage.getItem("ivKey")), c => c.charCodeAt(0)));
  let decrypted = decipher.update(encrypted, 'base64', 'utf8');
  return (decrypted + decipher.final('utf8'));
  }else{
    return encrypted;
  }*/
});

export const serverDateFormat = (dt) => {
  if (dt) { var d = formatDate(dt, 2); return d }
  else { return dt; }
}

export const defaultDateFormat = (dt,t='date') => {
  if (dt) { var d = formatDate(dt, 1 , t); return d }
  else { return dt; }
}

export const checkPermissions = () => {
  return true;
}

export const getPriceFormat = (v) => {
  return v;
}
export const globalVariable = {
  baseURL: baseURL,
  googleMapKey:googleMapKey,
  appVersion: '1.0'
}


export const timeSince = (date) => {
  var dt = new Date();
  dt.setMinutes(dt.getMinutes() - 330);


  var seconds = Math.floor((dt - new Date(date)) / 1000);
  var interval = seconds / 31536000;
  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
}

function formatDate(date, t,type = 'date') {
  if (date instanceof Date === false) {
    if (date.split('-').length > 1 || date.split('/').length > 1) {
      // date = date+' 12:00:00';
    }
  }
  var d = new Date(date);
  d.setHours(12);
  if(type !== 'time'){
    var month = '' + (d.getUTCMonth() + 1),
      day = '' + d.getUTCDate(),
      year = d.getUTCFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    if (t === 1) {
      return [day, month, year].join('/');
    } else {
      return [year, month, day].join('-');
    }
  }else{
    return ("0" + d.getUTCHours()).slice(-2)+':'+("0" + d.getUTCMinutes()).slice(-2)+' UTC'
  }
}